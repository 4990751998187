import { useEffect } from "react";
import Topbar from "../components/Topbar";
import FootView from "../views/FootView";
import * as React from "react";

const PolicyPage = (props: any) => {
  useEffect(() => {
    window.location.href = "https://www.saluto.ai";
  }, []);

  return <></>;

  return (
    <div className="w-screen overflow-hidden">
      <Topbar pathName={props.location.pathname}></Topbar>
      <div className="w-4/5 md:w-2/3 mx-auto">
        <br />
        <h1 className="text-2xl">
          1. Personuppgiftsansvar och grund för behandling
        </h1>
        <p>
          (1.1) Denna integritetspolicy gäller när Inference Labs AB (hädanefter
          ”Inference Labs”, ”vi”, ”vår” eller ”oss”), organisationsnummer
          559331-3439, Riddargatan 29, 114 57 Stockholm, utför behandling av
          personuppgifter som avser personer som använder Inference Labs
          tjänster (”du”, ”dig”, ”dina”). Inference Labs behandling av
          personuppgifter följer den allmänna dataskyddsförordningen (EU)
          2016/679, (”Dataskyddsförordningen”).
        </p>
        <br />
        <p>
          (1.2) Behandling av personuppgifter vid nyttjande av Inference Labs
          tjänster sker med grund i ett direkt avtal med dig (den registrerade)
          eller genom avtal med din arbetsgivare i de fall där du hänvisas till
          oss genom din arbetsgivare. Behandling sker även för att följa
          rättsliga förpliktelser enligt lag och myndighetsbeslut och för
          särskilda behandlingar med ditt samtycke.
        </p>
        <br />
        <h1 className="text-2xl">2. Ändamålen med behandlingen</h1>
        <p>
          2.1 Vi behandlar dina personuppgifter när detta är nödvändigt för att
          fullgöra våra skyldigheter enligt avtal med dig eller med din
          arbetsgivare och berättigade intressen. Vi måste behandla
          personuppgifter för att kunna leverera de tjänster som vi erbjuder och
          du kan därför inte vara en användare hos oss utan att vi behandlar
          dina personuppgifter. Detta innebär att vi kommer att behandla dina
          personuppgifter för följande ändamål;
        </p>
        <p className="ml-2">(a) administrering av provtagning;</p>
        <p className="ml-2">
          (b) tillhandahållande av provsvar och kommentarer;
        </p>
        <p className="ml-2">
          (c) motverka otillbörlig användning av tjänsterna, såsom kontroll av
          identitet;
        </p>
        <p className="ml-2">
          (d) kundanalys, affärsutveckling, utvärdering och intern analys (såsom
          programtester, analys, statistik och felsökning);
        </p>
        <p className="ml-2">
          (e) sköta kontakter med provtagningsställen, laboratorier och andra
          eventuella underleverantörer;
        </p>
        <p className="ml-2">(f) administration av betalning för tjänsten;</p>
        <p className="ml-2">
          (g) kontakter med dig och underhåll av kontaktvägar;
        </p>
        <p className="ml-2">
          (h) kommersiella meddelanden och marknadsföring (såsom erbjudanden
          från oss och nyhetsbrev); och
        </p>
        <p className="ml-2">
          (i) för säkerställa våra legala rättigheter, gentemot dig, mot tredje
          man och i tillämpliga fall mot din arbetsgivare;
        </p>
        <p className="ml-2">
          (j) efterlevande av tillämplig lagstiftning och myndighetsbeslut
          (såsom bland annat patientdatalagen och bokföringslagen)
          <br />
        </p>
        <br />
        <p>
          I de fall du har hänvisats till oss av din arbetsgivare, kan vi
          bedömas agera personuppgiftsbiträde åt denne och då behandlar vi dina
          personuppgifter enligt instruktion och på uppdrag av denne. Vi lämnar
          dock aldrig ut några känsliga personuppgifter till din arbetsgivare,
          dvs uppgifter gällande din hälsa inklusive huruvida du har nyttjat
          Salutos tjänster eller inte.
        </p>
        <br />
        <p>
          (2.2) Vi behandlar s.k. känsliga uppgifter, såsom uppgifter om din
          hälsa, endast för ändamålen att fullgöra våra skyldigheter att föra
          dokumentation som behövs för tillhandahållandet av tjänsten eller som
          vi är skyldiga att upprätta enligt lag, att ge förebyggande
          livsstilsförändrande rekommendationer avseende din hälsa,
          tillhandahålla provsvar till dig, fullgöra andra skyldigheter gentemot
          dig enligt avtalet med dig, utveckla och säkra kvaliteten i vår
          verksamhet samt för administration, planering, uppföljning,
          utvärdering och tillsyn av verksamheten.
        </p>
        <br />
        <h1 className="text-2xl">Personuppgifter som behandlas</h1>
        <p>
          (3.1) En personuppgift är all information som kan knytas till en
          levande person. Vi samlar in och behandlar olika typer av
          personuppgifter inom ramen för vår verksamhet. Viss information om dig
          kommer också att skapas genom de prov och tester som du kan beställa
          samt som inhämtande av tidigare provresultat och journalanteckningar
          som lämnas av laboratorier och kliniker som biträder oss.
        </p>
        <br />
        <p>
          (3.2) Följande personuppgifter kommer vi att samla in från dig när du
          använder våra tjänster:
        </p>
        <p className="ml-2">
          (a) Uppgift om din identitet – förnamn, efternamn, personnummer och
          kön.
        </p>
        <p className="ml-2">
          (b) Dina kontaktuppgifter – adress för faktura och leverans,
          e-postadress, telefonnummer.
        </p>
        <p className="ml-2">
          (c) Betalningsinformation – information för att genomföra betalningar
          till Inference Labs, eller utställa faktura.
        </p>
        <p className="ml-2">
          (d) Lämnade hälsouppgifter – information som du lämnar gällande din
          hälsa samt provresultat
        </p>
        <p className="ml-2">
          (e) Tidigare hälsouppgifter i den mån samtycke har lämnats -
          information om din hälsa, dina tidigare provresultat och uppgifter om,
          exempelvis, dina blodvärden samt läkares kommentar på resultaten och
          journaldata.
        </p>
        <p className="ml-2">
          (f) Uppgifter som du väljer att dela med Saluto genom synkronisering
          med applikationen Hälsa eller Google Fit och som kommer vara relevanta
          för tillhandahållandet av våra tjänster – avser information kopplat
          till ditt kroppsmått, blodtryck, din fysiska aktivitet och
          träningsdata såsom antalet tagna steg, puls, distans, hastighet, tid,
          kalorier samt övriga hälsouppgifter som du väljer att dela med Saluto
          såsom uppgifter kopplade till din sömn och kost.
        </p>
        <p className="ml-2">
          (g) Information om tjänst/prov – uppgift om vad för produkt (prov
          eller analys) som du har beställt.
        </p>
        <p className="ml-2">
          (h) Korrespondens: dina meddelanden till och från Inference Labs,
          vilket kan infatta beskrivning av ditt ärende och vårdpersonals
          rekommendationer.
        </p>
        <p className="ml-2">
          (i) IT-data – för att du ska kunna kommunicera med vårt system Saluto
          måste vi behandla uppgifter om den enhet du använder (dvs. din dator,
          smarta telefon eller liknande), exempelvis enhetens IP-adress.
        </p>
        <br />
        <p>
          (3.3) Om du kontaktar vår kundtjänst för hjälp med ett ärende eller
          för återbetalning när du använder tjänsten i egenskap av privatperson
          kommer ärendet att kräva att vi behandlar dina personuppgifter.
          Personuppgifter som behandlas inom ramen för kundtjänst kan exempelvis
          vara:
        </p>
        <p className="ml-2">
          (a) Uppgift om identitet – såsom förnamn, efternamn och personnummer.
          Om du i skrift också lämnar uppgift om andras identitet kommer vi inte
          att spara den informationen om det inte krävs för ärendet eller för
          att utreda bedrägeri eller liknande syfte.
        </p>
        <p className="ml-2">
          (b) Ärendebeskrivning – vid din kontakt och beskrivning av
          supportärendet kan vi inte styra vilka uppgifter du lämnar. Din
          ärendebeskrivning kan därför omfatta personuppgifter som vi inte har
          anledning att behandla. Sådana personuppgifter kommer inte vi att
          spara.
        </p>
        <p className="ml-2">
          (c) Återbetalningshantering – om kundtjänstärendet gäller eller leder
          till återbetalning kommer vi att behöva behandla uppgift om bankkonto
          för utbetalningen, samt pris och övriga uppgifter om köpet som
          återbetalningen avser.
        </p>
        <br />
        <h1 className="text-2xl">4. Mottagare av personuppgifter</h1>
        <p>
          (4.1) Vår tjänst är komplicerad och förutsätter att vi samarbetar med
          och interagerar med andra aktörer inom vården och att vi tar hjälp av
          utvecklare och andra leverantörer. Vi kommer därför att överföra dina
          personuppgifter och ta hjälp av andra aktörer för att behandla dina
          personuppgifter när det är nödvändigt för att
          <br />
          (i) uppfylla avtalet med dig,
          <br />
          (ii) efterleva lag, författning eller beslut. Vi använder oss av
          personuppgiftsbiträden som administrerar och hanterar uppgifter för
          vår räkning. Sådana personuppgiftsbiträden får inte använda eller dela
          dina personuppgifter på något annat sätt eller för andra ändamål än i
          enlighet med den här integritetspolicyn.
        </p>
        <br />
        <p>
          (4.2) Personuppgifter kan komma att delas med andra när det behövs för
          att uppfylla våra skyldigheter enligt avtalet med dig eller din
          arbetsgivare i tillämpliga fall, då vi har ett berättigat intresse
          eller för att vi är skyldiga att göra det för att följa lagar, regler
          eller beslut. Följande kategorier av mottagare kan komma ifråga;
        </p>
        <br />
        <p>
          (a) Provtagningsställen och laboratorier: Vi kommer att dela med oss
          av nödvändiga personuppgifter till provtagningsstället där du väljer
          att ta ditt prov i den mån det är nödvändigt för att
          provtagningsstället ska kunna identifiera dig och genomföra provet.
          Vidare samarbetar vi med laboratorier som analyserar provet och lämnar
          provsvar – i dessa fall kommer vi att dela med oss av dina
          personuppgifter till laboratorierna.
        </p>
        <br />
        <p>
          (b) Inhyrd personal och konsulter: I den mån vi använder inhyrd
          personal kommer sådan personal att få ta del av de personuppgifter om
          dig som är nödvändiga för att vi ska kunna uppfylla vårt avtal med
          dig. Om det är nödvändigt för utveckling av tjänsten, felsökning eller
          andra liknande tekniska åtgärder, kan vi komma att ge IT-konsulter och
          -utvecklare tillgång till vissa personuppgifter.
        </p>
        <br />
        <p>
          (c) Myndigheter: Vi kommer att dela dina personuppgifter till
          myndigheter och tillsynsorgan om vi är skyldiga att göra det enligt
          lag. I vissa fall kan vi vara förhindrade enligt lag att meddela dig
          om att en myndighet har begärt att få del av dina personuppgifter.
        </p>
        <br />
        <p>
          (d) Leverantörer av aviseringstjänster: För att skicka påminnelser,
          bekräftelser och annan automatisk kommunikation till dig kan vi komma
          att ge leverantörer av sådana aviseringstjänster tillgång till dina
          kontaktuppgifter i den mån det är nödvändigt.
        </p>
        <p>
          (4.3) Oavsett ovanstående följer vi alltid gällande lagstiftning
          beträffande dina personuppgifter som rör känsliga uppgifter. Det
          innebär bl.a. begränsad tillgång till sådana uppgifter och att de som
          får del av uppgifterna har tystnadsplikt.
        </p>
        <br />
        <p>
          (4.4) Vad gäller information som du väljer att dela med Saluto genom
          synkronisering med applikationen Hälsa (Apple Health) eller Google
          Fit, kommer dessa personuppgifter aldrig användas för marknadsföring
          eller liknande tjänster.
        </p>
        <br />
        <p>
          (4.5) Vår avsikt är att behandla alla personuppgifter inom EU/EES. Om
          personuppgifter skulle komma att överföras till ett land utanför
          EU/EES som inte tillämpar samma skyddsnivåer för behandlingen av
          personuppgifter, kommer vi i så fall att vidta lämpliga skyddsåtgärder
          för att säkerställa att behandlingen sker på en motsvarande skyddsnivå
          som den inom EU/EES. Det kan t.ex. ske genom användandet av s.k.
          standardavtalsklausuler.
        </p>
        <br />
        <p>
          (4.6) Känsliga uppgifter om dig, däribland dina hälsouppgifter,
          behandlas i enlighet med lag. Sådana uppgifter kommer därför bara att
          vara tillgängliga för sådan personal som ska ha tillgång till dem
          enligt lag. Uppgifterna kommer inte att lämnas ut eller överföras till
          någon mottagare annat än när så får ske enligt lag eller enligt ditt
          samtycke. Detta gäller även i förhållande till din arbetsgivare i de
          fall du använder våra tjänster i egenskap av anställd.
        </p>
        <br />
        <p>
          (4.7) Inga hälsouppgifter som vi behandlar förs utanför EU/EES av oss
          eller våra leverantörer.
        </p>
        <br />
        <h1 className="text-2xl">5. Bevarande av personuppgifter</h1>
        <p>
          (5.1) Personuppgifter bevaras så länge som är nödvändigt för att
          uppfylla de ändamål som beskrivits ovan. Det betyder att de flesta
          personuppgifter om dig kommer att raderas automatiskt efter det att en
          lagstadgad arkiveringstid har löpt ut eller när vårt kundförhållande
          med dig eller din arbetsgivare har upphört.
        </p>
        <br />
        <p>
          (5.2) Vi bevarar uppgifter som förekommer i mobilapplikationen Saluto
          i tio år från det att den sista uppgiften förts in i databasen, men du
          kan när som helst begära att vi tar bort din tillgänglighet till dem
          digitalt genom att återkalla ditt samtycke eller genom att avsluta
          ditt konto. Observera att vi, oavsett vid en sådan begäran, kan vara
          skyldiga att spara vissa av uppgifterna i databasen enligt
          patientdatalagen. En registrering som inte utnyttjas inom sex månader
          från registreringen, kommer resultera i en radering av dina
          personuppgifter en kort tid efter att de sex månaderna förflutit.
        </p>
        <br />
        <p>
          (5.3) Vi är skyldiga enligt bokföringslagen (1999:1078) att bevara
          vissa personuppgifter, ex. de som förekommer i fakturor och liknande
          bokföringsunderlag, i sju år. Personuppgifter som bevaras för
          bokföringsändamål kommer bara att användas för det syftet.
        </p>
        <br />
        <p>
          Vi står inte ansvariga för fel som beror på det laboratorium som
          utfört provtagning och analys eller tekniska fel som beror på antingen
          e-butiksleverantören, journalsystemsleverantören eller en annan tredje
          part.
        </p>
        <br />
        <p>
          (5.4) Uppgifter om dig som kopplas till ditt användarkonto på Saluto
          kommer att bevaras så länge ditt konto är öppet. Du kan välja att
          stänga ned ditt konto och vi kommer då att radera dina uppgifter när
          de inte behöver bevaras för andra syften, som beskrivits ovan.
        </p>
        <br />
        <h1 className="text-2xl">
          6. Gallring och rättelse av personuppgifter
        </h1>
        <p>
          (6.2) Innan uppgifterna används som underlag för statistik och
          produktutveckling avpersonifieras de och aggregeras, vilket betyder
          att de inte längre kan kopplas till dig, vare sig av oss eller av
          någon annan. Informationen innehåller då inte längre personuppgifter.
          I den mån samtycke har lämnats, får pseudonymiserade och
          avidentifierade personuppgifter användas för vidareutveckling av
          Saluto och dess tjänster samt för annat forskningsunderlag eller till
          statistik.
        </p>
        <br />
        <p>
          (6.3) När vi utför en gallring av personuppgifter kan denna inte
          återkallas/återskapas och när gallringen utförts kan ingen person
          längre associeras med den information som återstår.
        </p>
        <br />
        <p>
          (6.4) Du har rätt att begära ett utdrag som visar vilka
          personuppgifter vi har om dig och skulle någon uppgift vara felaktigt
          kan du begära att vi rättar uppgiften. Du har också rätt att begära
          att personuppgifterna raderas, förutsatt att uppgifterna inte längre
          behövs för de ändamål som de samlades in för, att dina skäl för
          invändningen mot behandlingen grundar sig på berättigade intressen som
          väger tyngre än våra skäl för fortsatt behandling, eller om det finns
          en laglig skyldighet att radera personuppgifterna. Radering sker
          antingen genom att uppgifterna tas bort eller avpersonifieras
        </p>
        <br />
        <p>
          (6.5) Du kan ha rätt att begära att få dina personuppgifter överförda
          till en annan personuppgiftsansvarig (s.k. dataportering). Det
          förutsätter att du har lämnat uppgifterna till oss och att vår rätt
          att behandla personuppgifterna grundar sig på fullgörande av
          åtaganden, som beskrivits ovan, samt att behandlingen kan ske
          automatiserat och att det är tekniskt möjligt. Det kan finnas vissa
          undantag till dina rättigheter som beskrivits ovan och din begäran kan
          därför komma att avslås om vi exempelvis är förhindrade enligt lag att
          bevilja din begäran.
        </p>
        <br />
        <p>
          (6.6) Om du vill begära ett registerutdrag eller rätta/radera en
          uppgift ber vi dig att vända dig till support@saluto.ai.
        </p>
        <br />
        <h1 className="text-2xl">7. Informationssäkerhet</h1>
        <p>
          (7.1) Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
          skydda de personuppgifter som behandlas i enlighet med avsnitt 2 av
          Dataskyddsförordningen. Vi har särskilda interna riktlinjer och
          processer för att hantera frågor om informationssäkerhet och för att
          förhindra och upptäcka läckor.
        </p>
        <br />
        <p>
          (7.2) Om dina personuppgifter omfattas av en inträffad
          säkerhetsincident (så kallad ”personuppgiftsincident”) kommer vi att
          kontakta dig i enlighet med Dataskyddsförordningen.
        </p>
        <br />
        <h1 className="text-2xl">8. Cookies</h1>
        <p>
          (8.1) På vår hemsida används cookies. Cookies är små textfiler som
          lagras på besökarens dator och som gör det möjligt att följa vad
          besökaren gör på hemsidan.
        </p>
        <br />
        <p>
          (8.2) Det finns två typer av cookies:
          <br />
          <p className="ml-2">
            (a) En permanent cookie som ligger kvar på besökarens dator under en
            bestämd tid.
            <br />
            (b) En sessionscookie som lagras tillfälligt i datorns minne under
            tiden en besökare är inne på en hemsida. Sessionscookies försvinner
            när du stänger din webbläsare.
          </p>
        </p>
        <br />
        <p>
          (8.3) Vi använder cookies för att vår hemsida ska fungera, för
          statistik och i syfte att möjliggöra annonsering.
        </p>
        <br />
        <p>
          (8.4) Vår hemsida innehåller även cookies från tredje part som noterar
          ditt besök till hemsidan för att möjliggöra annonsering på andra
          hemsidor.
        </p>
        <br />
        <p>
          (8.5) Ingen identifikationsinformation, som e-post eller namn, sparas
          om besökaren genom cookies.
          <br /> <br />
          (8.6) Besökaren kan välja att inte acceptera cookies genom att stänga
          av cookies i sin egen webbläsares säkerhetsinställningar.
          <br /> <br />
          (8.7) Besökaren kan även ställa in webbläsaren så att han eller hon
          får en fråga varje gång webbplatsen försöker placera en cookie på
          besökarens dator. Genom webbläsaren kan också tidigare lagrade cookies
          raderas. Se webbläsarens hjälpsidor för mer information om detta.
          <br /> <br />
          (8.8) Post- och telestyrelsen, som är tillsynsmyndighet på området,
          lämnar ytterligare information om cookies på sin webbplats
          (http://www.pts.se/).
        </p>
        <br />
        <h1 className="text-2xl">9. Mobilapplikationen Saluto</h1>
        <p>
          (9.1) Våra användare har möjlighet att själv läsa och ta del av sina
          hälso- och livsstilsrapporter. Under fliken ”Rapporter” kan du själv
          följa dina testresultat och värden över tid. Där kan du även hitta
          information om hur ditt resultat ska tolkas. Dessutom kan du själv
          välja att dela informationen med andra.
        </p>
        <br />
        <h1 className="text-2xl">10. Dina rättigheter</h1>
        <p>
          (10.1) Du har rätt att utan kostnad återkalla samtycke till en viss
          behandling utan att detta påverkar lagligheten av behandlingen innan
          återkallandet. Du kan exempelvis ha valt att samtycka till att vi
          kontaktar dig med nyhetsbrev och andra utskick. Du kan då välja att
          avregistrera dig genom att följa en länk i dessa utskick.
          <br /> <br />
          (10.2) Du har rätt att begära att behandlingen begränsas till lagring
          och att invända mot behandlingen.
          <br /> <br />
          (10.3) Du har alltid rätten att inge klagomål till tillsynsmyndigheten
          Integritetsskyddsmyndigheten.
          <br /> <br />
          (10.4) Om du vill återkalla ditt samtycke ber vi dig att vända dig att
          kontakta oss genom att skicka e-post till support@saluto.ai.
        </p>
      </div>
      <FootView pathName={props.location.pathname}></FootView>
    </div>
  );
};

export default PolicyPage;
